import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
  },
  theme: {
    themes: {
      light: {
        primary: "#260C30",
        secondary: "#142B3D",
        accent: "#FFCC83",
        error: "#b53b00",
        info: "#005fa9",
        success: "#276f36",
        warning: "#FFC107",
      },
    },
  },
});
