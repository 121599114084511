import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1].replace("_", "-");
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});

const locales = Object.keys(i18n.messages);

const getLocale = () => {
  const cachedLocale = localStorage.getItem("locale");
  if (cachedLocale !== null) {
    if (locales.includes(cachedLocale)) {
      return cachedLocale;
    }
  }

  let languages = navigator.languages;
  if (!languages) {
    languages = [navigator.language || "en"];
  }

  for (let i = 0; i < languages.length; i++) {
    const language = languages[i];
    for (let j = 0; j < locales.length; j++) {
      const locale = locales[j];
      if (language === locale) {
        return locale;
      } else if (language.split("-")[0] === locale) {
        return locale;
      }
    }
  }

  return process.env.VUE_APP_I18N_LOCALE;
};

i18n.locale = getLocale();

export default i18n;
