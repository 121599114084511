import Vue from "vue";
import Main from "./pages/Main.vue";
import vuetify from "./plugins/vuetify";

import "./assets/fonts/ReadexPro/style.css";
import i18n from "./i18n";

Vue.config.productionTip = false;

new Vue({
  vuetify,
  i18n,
  render: (h) => h(Main),
}).$mount("#app");
