








































































import Vue from "vue";

export default Vue.extend({
  name: "App",

  created() {
    if (window.location.pathname.startsWith("/sv")) {
      this.$i18n.locale = "sv";
      document.documentElement.setAttribute("lang", "sv");
      document.title = "Trelson Assessment App - Logga in";
      document
        .querySelector('meta[name="description"]')
        ?.setAttribute(
          "content",
          "Logga in som lärare eller elev för att komma åt Trelson Assessment"
        );
    } else if (window.location.pathname.startsWith("/es")) {
      this.$i18n.locale = "es-ES";
      document.documentElement.setAttribute("lang", "es");
      document.title = "Trelson Assessment Aplicación - Iniciar sesión";
      document
        .querySelector('meta[name="description"]')
        ?.setAttribute(
          "content",
          "Inicie sesión como profesor o alumno para acceder a Trelson Assessment"
        );
    }
  },
});
